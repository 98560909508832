import React from 'react'

const ChrisSvg = () => (
  <svg width="260" height="286" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M100 0c30.928 0 56 25.072 56 56v6.166c5.676.953 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.306 11.881-2.066 19.805-14.456 36.54-31.693 44.73L124 163h4c39.765 0 72 32.235 72 72v9H0v-9c0-39.765 32.235-72 72-72h4v-18.39c-17.237-8.188-29.628-24.923-31.695-44.729C38.481 99.058 34 94.052 34 88V74c0-5.946 4.325-10.882 10-11.834V56c0-30.928 25.072-56 56-56z"
        id="chris-a"
      />
      <path
        d="M83 36.348c0 16.281 22.162 29.48 49.5 29.48S182 52.629 182 36.348c0-1.363-.155-2.703-.456-4.017C210.784 41.488 232 68.792 232 101.051V110H32v-8.948c0-32.655 21.739-60.232 51.534-69.05A18.017 18.017 0 0083 36.348z"
        id="chris-c"
      />
      <path id="chris-e" d="M0 0h264v280H0z" />
      <path
        d="M116 0c11.21 0 21.908 2.196 31.685 6.181.923-.119 1.863-.181 2.815-.181 6.701 0 12.774 3.075 17.197 8.057 18.043.933 33.462 13.311 40.773 30.899C216.493 49.555 222 59.042 222 70c0 .58-.015 1.155-.046 1.725C228.261 81.265 232 93.135 232 106c0 7.33-1.214 14.339-3.425 20.776.279 1.692.425 3.438.425 5.224 0 9.455-4.1 17.816-10.38 22.885C211.259 172.685 195.39 185 177 185c-1.498 0-2.98-.082-4.44-.241-5.449 5.147-12.438 8.241-20.06 8.241-4.441 0-8.668-1.05-12.5-2.946l.001-9.444c17.53-8.328 30.047-25.496 31.79-45.743C177.57 134.003 182 129.02 182 123v-13c0-1.985-.482-3.857-1.335-5.507-2.698-.803-5.67-3.052-8.645-6.323a.203.203 0 00-.02-.004v-.018c-6.845-7.537-13.712-20.484-17.333-33.71-10.983 1.674-24.154 2.649-38.313 2.649-14.45 0-27.869-1.015-38.986-2.753C73.755 77.602 66.867 90.601 60 98.157v.009l-.01.003c-2.98 3.276-5.955 5.526-8.656 6.329A11.933 11.933 0 0050 110v13c0 6.019 4.431 11.003 10.209 11.867 1.743 20.248 14.26 37.416 31.791 45.744v9.443C88.17 191.949 83.943 193 79.5 193c-7.622 0-14.611-3.094-20.06-8.242-1.46.16-2.942.242-4.44.242-18.39 0-34.259-12.316-41.621-30.115C7.101 149.815 3 141.455 3 132c0-1.786.146-3.532.426-5.225C1.214 120.34 0 113.331 0 106c0-12.866 3.74-24.736 10.046-34.275-.03-.57-.046-1.146-.046-1.725 0-10.958 5.507-20.445 13.531-25.044 7.31-17.588 22.729-29.966 40.772-30.9C68.726 9.076 74.8 6 81.5 6c.952 0 1.892.062 2.816.183C94.092 2.196 104.79 0 116 0z"
        id="chris-g"
      />
      <filter x="-3.1%" y="-9.8%" width="106.2%" height="119.5%" filterUnits="objectBoundingBox" id="chris-i">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(32 42)">
        <mask id="chris-b" fill="#fff">
          <use xlinkHref="#chris-a" />
        </mask>
        <use fill="#D0C6AC" xlinkHref="#chris-a" />
        <g mask="url(#chris-b)" fill="#614335">
          <path d="M-32 0h264v244H-32z" />
        </g>
        <path
          d="M44 94c0 30.928 25.072 56 56 56s56-25.072 56-56v8c0 30.928-25.072 56-56 56s-56-25.072-56-56z"
          fillOpacity=".1"
          fill="#000"
          mask="url(#chris-b)"
        />
      </g>
      <g transform="translate(0 176)">
        <mask id="chris-d" fill="#fff">
          <use xlinkHref="#chris-c" />
        </mask>
        <use fill="#E6E6E6" xlinkHref="#chris-c" />
        <g mask="url(#chris-d)" fill="#5199E4">
          <path d="M0 0h264v110H0z" />
        </g>
      </g>
      <g fill="#000">
        <path d="M118 155c0 7.732 6.268 14 14 14s14-6.268 14-14" fillOpacity=".7" />
        <path d="M120 136c0 4.418 5.373 8 12 8s12-3.582 12-8" fillOpacity=".16" />
        <g transform="translate(76 96)" fillOpacity=".6">
          <circle cx="30" cy="22" r="6" />
          <circle cx="82" cy="22" r="6" />
        </g>
        <g fillRule="nonzero" fillOpacity=".6">
          <path d="M91.63 105.159c3.915-5.51 14.648-8.598 23.893-6.328a2 2 0 00.954-3.884c-10.737-2.637-23.165.94-28.107 7.894a2 2 0 003.26 2.318zM172.37 105.159c-3.915-5.51-14.648-8.598-23.893-6.328a2 2 0 01-.954-3.884c10.737-2.637 23.165.94 28.108 7.894a2 2 0 01-3.26 2.318z" />
        </g>
      </g>
      <g transform="translate(0 6)">
        <mask id="chris-f" fill="#fff">
          <use xlinkHref="#chris-e" />
        </mask>
        <g mask="url(#chris-f)">
          <path
            d="M96.39 42.392l.27-.037c10.433-1.44 22.643-2.268 35.694-2.268 12.875 0 24.932.806 35.272 2.21.158-2.961.51-5.701 1.038-8.183C186.354 45.953 198 66.116 198 89v20.777c-9.02-1.069-21.87-20.385-27.333-40.34-10.982 1.675-24.153 2.65-38.313 2.65-14.45 0-27.869-1.015-38.986-2.753-5.446 19.997-18.33 39.384-27.368 40.444V89c0-22.887 11.65-43.053 29.343-54.892.535 2.51.889 5.284 1.047 8.284z"
            fillOpacity=".16"
            fill="#000"
          />
          <g transform="translate(16)">
            <mask id="chris-h" fill="#fff">
              <use xlinkHref="#chris-g" />
            </mask>
            <use fill="#314756" xlinkHref="#chris-g" />
            <g mask="url(#chris-h)" fill="#2C1B18">
              <path d="M-16 0h264v280H-16z" />
            </g>
          </g>
          <g filter="url(#chris-i)" transform="translate(67 92)" fill="#252C2F" fillRule="nonzero">
            <path d="M35.47 0C57.183 0 59 10.127 59 20.5 59 32.569 48.721 41 34 41h-2.758C17.315 41 9 33.336 9 20.5 9 10.127 10.817 0 32.53 0h2.94zm.145 6h-3.23C13 6 13 12.841 13 21.502 13 28.572 16.116 37 30.97 37H34c12.365 0 21-6.373 21-15.498v-.775C54.982 12.437 54.417 6 35.614 6zM97.47 0C119.183 0 121 10.127 121 20.5 121 32.569 110.721 41 96 41h-2.758C79.315 41 71 33.336 71 20.5 71 10.127 72.817 0 94.53 0h2.94zm.145 6h-3.23c-18.803 0-19.368 6.437-19.384 14.727l-.001.775C75 28.572 78.12 37 92.97 37H96c12.365 0 21-6.373 21-15.498C117 12.84 117 6 97.615 6z" />
            <path d="M2.955 5.772C3.645 5.096 11.21 0 32.5 0c17.851 0 21.63 1.853 27.35 4.652l.419.207c.398.14 2.431.83 4.81.907a16.998 16.998 0 004.563-.869C76.17 1.722 82.562 0 97.5 0c21.29 0 28.854 5.096 29.545 5.772 1.634 0 2.955 1.29 2.955 2.885v2.886c0 1.596-1.32 2.886-2.955 2.886 0 0-6.901 0-6.901 2.886 0 2.885-1.962-4.176-1.962-5.772v-2.81c-3.603-1.38-10.054-3.947-20.682-3.947-11.842 0-17.739 2.1-22.798 4.185l.057.137-.003 1.986-2.217 5.35L69.8 15.36c-.244-.097-.772-.27-1.504-.451-2.04-.503-4.137-.656-5.992-.276-.68.14-1.312.35-1.891.633l-2.643 1.29-2.643-5.16.117-2.295.08-.195c-4.362-2.033-8.385-4.12-22.824-4.12-10.628 0-17.078 2.565-20.682 3.944v2.812c0 1.596-2.954 8.657-2.954 5.772 0-2.886-5.91-2.886-5.91-2.886-1.63 0-2.954-1.29-2.954-2.886V8.657c0-1.595 1.324-2.885 2.955-2.885z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ChrisSvg
