import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { borderRadius, margin, screens } from '../../tailwind'

const Container = styled.div`
  ${tw`flex shadow rounded mt-8 p-4`}
  background-color: #F9F9F9;
  min-width: 300px;
  width: 100%;

  @media (min-width: ${screens.md}) {
    width: auto;
  }

  @media (min-width: ${screens.xl}) {
    width: 500px;
  }
`

const Box = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  background-color: #d8d8d8;
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: ${({ rounded = 'default' }) => borderRadius[`${rounded}`]};
  margin-right: ${({ mr = 0 }) => margin[`${mr}`]};
`

const Details = styled.div`
  ${tw`flex flex-col justify-between`};
  flex: 1 1 auto;
  min-height: 4.5rem;
`

const Name = styled.span`
  ${tw`font-bold text-lg`}
`

const Subtle = styled.span`
  ${tw`inline-block text-grey-dark text-xs ml-3`}
`

const Accent = styled.span`
  ${tw`inline-block font-bold`}
`

const Slack = () => (
  <Container>
    <Box size="50px" mr="3" />
    <Details>
      <div>
        <Name>Alex Morris</Name>
        <Subtle>11:24 AM</Subtle>
      </div>
      <div>What do you use for empty states and onboarding?</div>
      <div>
        <Box size="14px" rounded="sm" mr="1" />
        <Box size="14px" rounded="sm" mr="3" />
        <Accent>7 replies</Accent>
        <Subtle>Last reply 12 days ago</Subtle>
      </div>
    </Details>
  </Container>
)

export default Slack
