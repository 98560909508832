import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import Img from 'gatsby-image'
import config from '../../config/website'
import { colors } from '../../tailwind'

// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import TagList from '../components/TagList'
import PersonaCard from '../components/PersonaCard'
import Slack from '../components/Slack'
import Footer from '../components/Footer'

// Elements
import {
  BigBox,
  BulletList,
  ProjectInner,
  HeaderBigBox,
  ImageCaption,
  ImageContainer,
  ImageContainerFull,
  ProjectHeader,
  ProjectDescription,
  ProjectDate,
  ProjectTitle,
  ProjectRow,
  ProjectRowPersona,
  ProjectSection,
  ProjectSectionTitle,
  ProjectSectionText,
  ProjectList,
  ProjectCallout,
} from '../elements/Projects'
import { StyledLinkOut } from '../elements/Links'
import NextProject from '../components/NextProject'
import ChrisSvg from '../svgs/Chris'
import RohanSvg from '../svgs/Rohan'
import OrchestrationDemoVideo from '../videos/orchestration/orchestration-demo.mp4'

const VideoContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 84%;
  height: 65%;
  transform: translate(-44%, -69%);
`

const Video = styled.video`
  width: 100%;
`

const theme = {
  menu: colors.white,
  menuHover: colors.black,
  accent: colors['am-purple'],
  ally: colors['am-purple-ally'],
}

const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
const homeURL = `${config.siteUrl}${realPrefix}`
const image = `${homeURL}/orchestration.jpg`

const Page = ({ data }) => {
  const images = {}
  data.orchestration.images.forEach(({ name, childImageSharp }) => {
    images[name] = childImageSharp
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Helmet>
          <meta property="og:image" content={image} />
        </Helmet>
        <Menu color={colors.white} />
        <HeaderBigBox>
          <ProjectInner>
            <ImageContainer>
              <Img fluid={images.orchestration.fluid} />
            </ImageContainer>
          </ProjectInner>
        </HeaderBigBox>
        <ProjectInner>
          <ProjectHeader>
            <ProjectDate>Spring 2020</ProjectDate>
            <ProjectTitle>Platform-wide orchestration</ProjectTitle>
            <ProjectDescription>
              Allow Data Scientists and Machine Learning Operations Engineers to create automated processes out of data,
              ML models, and more.
            </ProjectDescription>
            <TagList tags={['Research', 'UI', 'UX', 'Software', 'Strategy']} />
          </ProjectHeader>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Inception</ProjectSectionTitle>
              <ProjectSectionText>
                Unlike any tool of its kind, this canvas based UI will enable Data Scientists and Machine Learning
                Operations Engineers to automate operations between platform assets to control end to end lifecycle
                processes. The tool will be built to be compatible with the open-source Kubeflow tool for easy user
                adoption. The tool is currently in development and has not been released to the public.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>My contribution</ProjectSectionTitle>
              <ProjectSectionText>
                I was the lead designer for the orchestration editor tool that will appear in{' '}
                <StyledLinkOut href="https://www.ibm.com/cloud/watson-studio" target="_blank" rel="noopener noreferrer">
                  Watson Studio
                </StyledLinkOut>{' '}
                and{' '}
                <StyledLinkOut
                  href="https://www.ibm.com/products/cloud-pak-for-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cloud Pak for Data
                </StyledLinkOut>{' '}
                at the end of 2020. I directed weekly syncs to showcase the evolution of the design to a
                cross-functional team of Developers, System Architects, Data Scientists, and Product Managers across
                four time zones (from San Francisco to Poland).
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection>
              <ProjectSectionTitle>Tools</ProjectSectionTitle>
              <ProjectSectionText>
                <ul>
                  <li>Keynote</li>
                  <li>Sketch</li>
                  <li>Invision</li>
                  <li>Mural</li>
                </ul>
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <ImageContainerFull>
          <Img fluid={images['orchestration-mural'].fluid} />
        </ImageContainerFull>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Creating a vision</ProjectSectionTitle>
              <ProjectSectionText>
                I created and facilitated a design session for our cross-functional team via Mural to begin aligning on
                a vision. The tightly packed agenda of six exercises in one hour allowed a forum for 20 teammates to
                ultimately reveal their vision without technical constraints. All the exercises were chosen based on the
                nature of the event: virtual, fast, and direct. Below is an overview of some of the exercises:
              </ProjectSectionText>
              <ProjectSectionText>
                1. <strong>Hopes and Fears:</strong> Team members start the dialog by opening up about insecurities
                which directly squashes imposter syndrome by revealing other teammates have similar concerns. This built
                trust amongst a new team and opened a dialogue on the product vision.
              </ProjectSectionText>
              <ProjectSectionText>
                2. <strong>Assumptions and Questions:</strong> Because our team was spread across four time zones it was
                important to note what was known and not known about the project. Communication continued to be the
                biggest challenge throughout the project and by starting here, we all began on the same playing field.
              </ProjectSectionText>
              <ProjectSectionText>
                3. <strong>As-is journey mapping:</strong> Ending the first half of the design session, we mapped how
                users accomplish their goals currently, with no solution in place. This revealed pain points that I
                addressed when it was time for me to prototype.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection mt="3.5rem">
              <ProjectSectionText>
                4. <strong>People + Actions + Objects:</strong> The second half of the session got down into the Who is
                our user? What actions do they need to take? What objects do they need? This revealed goals that we then
                prioritized into a draft product roadmap.
              </ProjectSectionText>
              <ProjectCallout style={{ lineHeight: 1.5 }}>
                <p>
                  “Thanks Alex! I thought todays’ session was very useful, and we got quite a lot of ideas out on the
                  table in 1 hour. I think we clearly saw there were some different viewpoints on the personas involved.
                  I’ve spent much longer in design thinking sessions and accomplished less.”
                </p>
                <div style={{ fontSize: 16 }}>- Technical Product Manager and Lead Platform Architect</div>
              </ProjectCallout>
              <ProjectSectionText>
                This session led to a vision deck that I presented back to the team. It was a synthesis of everything
                shared on Mural plus my perspective on where the design should head.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Who, what, when, where?</ProjectSectionTitle>
              <ProjectSectionText>
                At this stage, I learned basic answers to these questions that allowed me enough understanding to start
                prototyping.
              </ProjectSectionText>
              <ProjectSectionText>
                <strong>Who:</strong> Data Scientists and ML Ops Engineers
              </ProjectSectionText>
              <ProjectSectionText>
                <strong>What:</strong> Combine a number of data analytics assets and actions (such as data, databases,
                data flows, ML models, accuracy checks, etc) in order to build a flow across many of IBM’s existing
                tooling
              </ProjectSectionText>
              <ProjectSectionText>
                <strong>When:</strong> After a user has created analytic assets that they want to use in active
                learning, learning, model deployment, or model training
              </ProjectSectionText>
              <ProjectSectionText>
                <strong>Where:</strong> Accessed from either Cloud Pak for Data or Watson Studio
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRowPersona>
            <PersonaCard
              image={<ChrisSvg />}
              name="Chris"
              job="Data Scientist"
              task=""
              need="Training ML models"
              product="the orchestration editor"
              capabilities={[
                'Prepare data',
                'Run python notebooks',
                'Train and save models',
                'Evaluate and prepare models',
              ]}
            />
            <PersonaCard
              image={<RohanSvg />}
              name="Rohan"
              job="Ops Engineer"
              task=""
              need="Deploy and monitor assets into production"
              product="the orchestration editor"
              capabilities={[
                'Run assets created by Chris and other data scientists',
                'Deploy assets into production',
                'Configure model monitoring',
              ]}
            />
          </ProjectRowPersona>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Syncing designs across IBM</ProjectSectionTitle>
              <ProjectSectionText>
                Within IBM Data and AI, there are four canvas based tool teams ranging in maturity from two to 10 years
                old. Each team is based on a common canvas which gets customized per the tools individual needs. I
                reached out to these teams for feedback which allowed me to understand the design system through various
                applications of a canvas tool in different scenarios.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection>
              <Slack />
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Prototyping stage</ProjectSectionTitle>
              <ProjectSectionText>
                I began wireframing by combining our priorities from the design session and my knowledge from the canvas
                teams and sketched several user flows with different considerations such as:
              </ProjectSectionText>
              <BulletList>
                <li>How the user should enter the tool</li>
                <li>What appears on the canvas and how to those objects link together</li>
                <li>What interactions are available in the canvas</li>
                <li>What actions users should take at the beginning, during, and end of their process</li>
              </BulletList>
            </ProjectSection>
            <ProjectSection mt="3.5rem">
              <BulletList>
                <li>What outputs does the tool create</li>
                <li>How does the tool link across other tools in the platform</li>
                <li>How users hand off to each other and communicate in the tool</li>
              </BulletList>
              <ProjectSectionText style={{ marginTop: '2rem' }}>
                The following screens were considered in our cross-functional team sync. I was able to deliver lots of
                ideas quickly which generated lots of conversation and more alignment towards our MVP.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <ImageContainerFull>
          <Img fluid={images['orchestration-prototyping'].fluid} />
        </ImageContainerFull>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Delivering on an MVP</ProjectSectionTitle>
              <ProjectSectionText>
                After receiving feedback from both my team and the canvas teams across IBM Data and AI, I updated and
                designed an MVP flow for my team to review via Invision. Because my team had challenges meeting due to
                extreme differences in time zones, the Invision prototype allowed feedback and discussions to happen at
                any time, which sped up the process.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <BigBox>
          <ProjectInner>
            <ImageContainer style={{ margin: '4rem auto' }}>
              <Img fluid={images['orchestration-imac'].fluid} />
              <VideoContainer>
                <Video autoPlay muted loop controls playsinline>
                  <track kind="captions" />
                  <source src={OrchestrationDemoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </Video>
              </VideoContainer>
            </ImageContainer>
          </ProjectInner>
        </BigBox>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Wrap up</ProjectSectionTitle>
              <ProjectSectionText>
                This project was handed off to another designer to execute user testing, final iteration, and hand off
                to developers to be released at the end of 2020.
              </ProjectSectionText>
              <ProjectSectionText>This project will result in:</ProjectSectionText>
              <ProjectSectionText>
                <ProjectList>
                  <li>One place to access assets (data, ML models, etc) from 8+ IBM data analytics tools</li>
                  <li>
                    New users with no coding experience are able to access the same functionality as their technical
                    counterparts
                  </li>
                </ProjectList>
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ImageContainer>
              <Img fluid={images['orchestration-wrap-up'].fluid} />
              <ImageCaption>
                My design team after an escape room <span role="img">😊</span>
              </ImageCaption>
            </ImageContainer>
          </ProjectRow>
        </ProjectInner>
        <NextProject to="/pollinate" bg={colors['am-yellow']} color={colors['am-yellow-ally']}>
          IBM Pollinate
        </NextProject>
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  {
    orchestration: allFile(filter: { relativePath: { glob: "orchestration/**/*" } }) {
      images: nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
