import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Img from 'gatsby-image'
import { ProjectList } from '../elements/Projects'

const Container = styled.div`
  ${tw`relative shadow my-8 mx-auto`}
  background-color: #F9F9F9;
  max-width: 344px;
  transition: all ease-in-out 0.2s;

  &:hover {
    ${tw`shadow-lg`}
    transform: translateY(-2px);
  }
`

const ImgContainer = styled.div`
  ${tw`flex justify-center pt-8 sm:px-8`}
  background-color: ${props => props.theme.accent};
`

const Details = styled.div`
  ${tw`flex flex-col p-6`}
  min-height: 10rem;
`

const Title = styled.span`
  ${tw`inline-block font-bold text-2xl mb-4`}
`

const HoverTitle = styled(Title)`
  margin-bottom: 3rem;
`

const Subtitle = styled.span`
  ${tw`ml-4 font-bold text-sm`}
`

const OnHover = styled.div`
  ${tw`absolute py-12 px-6`}
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0.96);
  transition: all ease-in-out 0.2s;

  &:hover {
    opacity: 1;
  }
`

const PersonaCard = ({ image, name, job, task, need, product, capabilities = [] }) => (
  <Container>
    <ImgContainer>
      {/* <Img fluid={image} /> */}
      {image}
    </ImgContainer>
    <Details>
      <div>
        <Title>{name}</Title>
        <Subtitle>{job}</Subtitle>
      </div>
      {task && <p>Task: {task}</p>}
      <p>Need: {need}</p>
    </Details>
    <OnHover>
      <HoverTitle>
        With {product} {name} can now:
      </HoverTitle>
      <ProjectList>
        {capabilities.map(capability => (
          <li key={capability}>{capability}</li>
        ))}
      </ProjectList>
    </OnHover>
  </Container>
)

PersonaCard.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  task: PropTypes.string.isRequired,
  need: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PersonaCard
